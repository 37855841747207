import cacheAngularTemplate from 'cacheAngularTemplate';
import { DisconnectedError } from 'DisconnectedError';
import { react2Angular } from 'FrontRoyalReact2Angular';
import { getOfflineStreams, ALL_CONTENT_STORED } from 'StoredContent';
import { deriveActivePlaylist } from 'StudentDashboard';
import { targetBrandConfig } from 'AppBranding';
import { studentDashboardConfig } from 'AdmissionsGuidance';
import { getProgramInclusion, getIsCurrentOrHasCompletedActiveProgram } from 'Users';
import { FeaturedStudents } from 'FeaturedStudents';
import { navigationHelper } from 'navigationHelper';
import { ProgramAchievementGraphic } from 'ShareableGraphics/components/ProgramAchievementGraphic/ProgramAchievementGraphic';
import { BioSigMixin } from 'BioSig';
import efficacySidebarTemplate from '../../views/stream/student_dashboard_efficacy_sidebar.html';
import coursesFlatTemplate from '../../views/stream/student_dashboard_courses_flat.html';
import toggleableCourseListButtonsTemplate from '../../views/stream/toggleable_course_list_buttons.html';
import coursesTopicTemplate from '../../views/stream/student_dashboard_courses_topic.html';
import topMessageBoxTemplate from '../../views/stream/student_dashboard_top_message_box.html';
import studentDashboardSidebarTemplate from '../../views/stream/student_dashboard_sidebar.html';
import template from '../../views/stream/student_dashboard.html';
import angularModule from '../lessons_module';
import getEnrollmentSidebarTodoGroups from './getEnrollmentSidebarTodoStuff';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.component(
    'featuredStudents',
    react2Angular(FeaturedStudents, ['inStudentNetwork', 'loadRoute'], '', false),
);
angularModule.component(
    'programAchievementGraphic',
    react2Angular(ProgramAchievementGraphic, ['variant', 'collapsed', 'currentUser', 'xsOrSm'], 'h-full', false),
);

cacheAngularTemplate(angularModule, 'Lessons/student_dashboard_sidebar.html', studentDashboardSidebarTemplate);

angularModule.directive('studentDashboard', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const Playlist = $injector.get('Playlist');
        const StreamDashboardDirHelper = $injector.get('Stream.StreamDashboardDirHelper');
        const $filter = $injector.get('$filter');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const RouteAnimationHelper = $injector.get('RouteAnimationHelper');
        const ShareService = $injector.get('Navigation.ShareService');
        const SiteMetadata = $injector.get('SiteMetadata');
        const DialogModal = $injector.get('DialogModal');
        const HasToggleableDisplayMode = $injector.get('HasToggleableDisplayMode');
        const TranslationHelper = $injector.get('TranslationHelper');
        const LearnerContentCache = $injector.get('LearnerContentCache');
        const Stream = $injector.get('Lesson.Stream');
        const ClientStorage = $injector.get('ClientStorage');
        const Cohort = $injector.get('Cohort');
        const $window = $injector.get('$window');
        const ContentAccessHelper = $injector.get('ContentAccessHelper');
        const isMobile = $injector.get('isMobileMixin');
        const offlineModeManager = $injector.get('offlineModeManager');
        const safeApply = $injector.get('safeApply');
        const frontRoyalStore = $injector.get('frontRoyalStore');
        const EventLogger = $injector.get('EventLogger');
        const timerSingleton = $injector.get('timerSingleton');
        const $timeout = $injector.get('$timeout');
        const { loadRoute } = navigationHelper($injector);

        return {
            scope: {},
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',
            link(scope) {
                scope.topMessageBoxTemplate = topMessageBoxTemplate;
                scope.coursesTopicTemplate = coursesTopicTemplate;
                scope.toggleableCourseListButtonsTemplate = toggleableCourseListButtonsTemplate;
                scope.coursesFlatTemplate = coursesFlatTemplate;
                scope.efficacySidebarTemplate = efficacySidebarTemplate;
                scope.loadRoute = loadRoute;
                scope.hideEventsBoxProxy = { val: false, shouldFetch: true };

                isMobile.onLink(scope);
                BioSigMixin.onLink(scope, $injector);

                //---------------------------
                // Initialization
                //---------------------------

                scope.offlineModeManager = offlineModeManager;
                const translationHelper = new TranslationHelper('lessons.stream.student_dashboard');
                let hasAvailableIncompleteStreams;
                scope.desktopSidebarBoxes = [];
                scope.mobileSidebarBoxes = [];

                // Get current user onto the scope
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.$watch('currentUser.shouldFetchUpcomingEvents', val => {
                    // See featured_events_box_dir.ts for what this is doing
                    if (val === false) scope.hideEventsBoxProxy.shouldFetch = false;
                });

                Object.defineProperty(scope, 'brandConfig', {
                    get() {
                        return targetBrandConfig(scope.currentUser);
                    },
                });

                // we want to listen for when mobile dashboard boxes are opened
                // because we want to ensure we don't generate a new config while it is open
                // which would reset the state and close the mobile box
                const endOpenListen = scope.$on('mobile-box-opened', () => {
                    scope.mobileBoxOpen = true;
                });

                const endCloseListen = scope.$on('mobile-box-closed', () => {
                    scope.mobileBoxOpen = false;
                });

                scope.$on('$destroy', () => {
                    endOpenListen();
                    endCloseListen();
                });

                scope.$watch(
                    () => getProgramInclusion(scope.currentUser)?.studentNetworkActivated,
                    studentNetworkActivated => {
                        scope.inStudentNetwork = !!studentNetworkActivated;
                    },
                );

                scope.$watchGroup(
                    [
                        () => studentDashboardConfig($injector),
                        () => offlineModeManager.inOfflineMode,
                        () => scope.mobileBoxOpen,
                    ],
                    newValues => {
                        const [currentStudentDashboardConfig, inOfflineMode, mobileBoxOpen] = newValues;
                        if (mobileBoxOpen) return;

                        // Users in programs with `supportsStudentDashboardConfig = false` won't receive a studentDashboardConfig,
                        // so we should exit early when that happens.
                        if (!currentStudentDashboardConfig) {
                            return;
                        }

                        scope.studentDashboardConfig = currentStudentDashboardConfig;

                        scope.showDesktopProgramBox =
                            scope.studentDashboardConfig.showDesktopProgramBox && !inOfflineMode;
                        scope.showMobileProgramBox =
                            scope.studentDashboardConfig.showMobileProgramBox && !inOfflineMode;
                    },
                );

                scope.$watch('studentDashboardConfig', dashboardConfig => {
                    if (!dashboardConfig) return;

                    dashboardConfig.mobileSidebarBoxes().then(mobileSidebarBoxes => {
                        scope.mobileSidebarBoxes = mobileSidebarBoxes;
                    });

                    dashboardConfig.desktopSidebarBoxes().then(desktopSidebarBoxes => {
                        scope.desktopSidebarBoxes = desktopSidebarBoxes;
                    });
                });

                StreamDashboardDirHelper.onLink(scope);
                NavigationHelperMixin.onLink(scope);
                HasToggleableDisplayMode.onLink(scope, 'toggleableCourseListStudentDashboard', true, true);

                // Set up header
                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.showAlternateHomeButton = false;
                SiteMetadata.updateHeaderMetadata();

                // ensure that we're at the top of the page when we navigate to here
                $injector.get('scrollHelper').scrollToTop();

                //---------------------------
                // Navigation
                //---------------------------

                scope.openCourses = () => {
                    RouteAnimationHelper.animatePathChange('/courses', 'slide-left');
                };

                scope.openSettings = () => {
                    RouteAnimationHelper.animatePathChange('/settings', 'slide-left');
                };

                scope.browseTopic = topicName => {
                    RouteAnimationHelper.animatePathChange(`/courses?topics[]=${topicName}`, 'slide-left');
                };

                //---------------------------
                // Display Helpers
                //---------------------------

                // determine if user in the special blue ocean high school group
                Object.defineProperty(scope, 'blueOceanHighSchooler', {
                    get() {
                        return scope.currentUser && scope.currentUser.sign_up_code === 'BOSHIGH';
                    },
                });

                // whether to show the course little toggle button
                Object.defineProperty(scope, 'showToggleableCourseButtons', {
                    get() {
                        return !scope.blueOceanHighSchooler;
                    },
                });

                // whether to show the recent streams box
                Object.defineProperty(scope, 'showRecentStreams', {
                    get() {
                        return scope.recentStreams && scope.recentStreams.length > 0 && !scope.topNavInfo;
                    },
                });

                // whether to show the efficacy study tips
                Object.defineProperty(scope, 'showEfficacyStudyTips', {
                    get() {
                        return scope.currentUser && scope.currentUser.inEfficacyStudy && !scope.topNavInfo;
                    },
                });

                Object.defineProperty(scope, 'enrollmentSubTextMessageLocaleKey', {
                    get() {
                        return offlineModeManager.inOfflineMode ? 'follow_these_steps_online' : 'follow_these_steps';
                    },
                });

                //---------------------------
                // User Specific Display
                //---------------------------

                // Signal that we should no longer send a mobile user through onboarding after they have
                // successfully authenticated and loaded the dashboard. This prevents the user from seeing
                // the onboarding again if they log out and close the app.
                if ($window.CORDOVA) {
                    ClientStorage.setItem('skip_onboarding', true);
                }

                //---------------------------
                // Sharing Helper (not used since tour was taken out, but will likely reappear soon)
                //---------------------------

                scope.share = (provider, label = 'tour-end') => {
                    ShareService.share(label, provider, SiteMetadata.smartlyShareInfo(scope.currentUser));
                };

                //---------------------------
                // Parsing for Display Flat List of Courses View
                //---------------------------

                let myCourses;

                let completedCourses;

                // creates streams, grouped into separate columns
                function createStreamGroups(streams) {
                    if (!streams) {
                        return;
                    }

                    scope.myCoursesStreamGroup = {
                        title: translationHelper.get('my_courses_title'),
                        anchor: 'my-courses',
                        streams: [],
                    };
                    myCourses = scope.myCoursesStreamGroup;

                    scope.completedCoursesStreamGroup = {
                        title: translationHelper.get('completed_courses_title'),
                        anchor: 'completed-courses',
                        streams: [],
                    };
                    completedCourses = scope.completedCoursesStreamGroup;

                    const inProgressCourses = [];
                    const bookmarkedCourses = [];
                    const activePlaylistCourses = [];
                    const orderedStreams = $filter('orderBy')(streams, 'title');
                    orderedStreams.forEach(stream => {
                        let streamGroup;

                        if (stream.progressStatus() === 'completed') {
                            streamGroup = completedCourses;
                        } else {
                            streamGroup = myCourses;
                        }

                        // we only want to feed Keep Learning streams that are in-progress
                        if (stream.progressStatus() === 'in_progress') {
                            inProgressCourses.push(stream);
                        }

                        // Check if it belongs to either the playlist or bookmarked courses, but don't put in both groups
                        if (
                            scope.activePlaylist &&
                            _.includes(scope.activePlaylist.streamLocalePackIds, stream.localePackId)
                        ) {
                            activePlaylistCourses.push(stream);
                        } else if (stream.favorite && stream.progressStatus() !== 'completed') {
                            bookmarkedCourses.push(stream);
                        }

                        // Add the stream to the group
                        streamGroup.streams.push(stream);
                    });

                    const streamGroups = [];
                    [myCourses, completedCourses].forEach(streamGroup => {
                        streamGroups.push(streamGroup);
                    });

                    // Sort completed courses by date completed DESC
                    scope.completedCoursesStreamGroup.streams = _.sortBy(
                        scope.completedCoursesStreamGroup.streams,
                        stream => stream.lastProgressAt,
                    ).reverse();

                    // Set view logic variables
                    scope.topNavInfo = undefined;
                    scope.activePlaylistCourses = activePlaylistCourses;
                    scope.bookmarkedCourses = bookmarkedCourses;
                    scope.streamGroups = streamGroups;
                    scope.noStreamsAvailable = myCourses.streams.length === 0 && completedCourses.streams.length === 0;
                    scope.allStreamsCompleted = completedCourses.streams.length > 0 && myCourses.streams.length === 0;

                    scope.$watchGroup(
                        ['activePlaylist', () => myCourses.streams, () => offlineModeManager.inOfflineMode],
                        () => {
                            scope.keepLearningStream = null;
                            const keepLearningStream = Stream.keepLearningStream(
                                scope.activePlaylist,
                                myCourses.streams,
                            );

                            if (!keepLearningStream) {
                                return;
                            }

                            if (!offlineModeManager.inOfflineMode) {
                                scope.keepLearningStream = keepLearningStream;
                                return;
                            }

                            offlineModeManager.streamIsAvailableOffline(keepLearningStream).then(availableOffline => {
                                if (availableOffline) {
                                    scope.keepLearningStream = keepLearningStream;

                                    // streamIsAvailableOffline returns a native promise
                                    safeApply(scope);
                                }
                            });
                        },
                    );

                    scope.$watch('keepLearningStream', keepLearningStream => {
                        scope.keepLearningLesson = keepLearningStream && Stream.keepLearningLesson(keepLearningStream);
                    });

                    // This is only relevant to users who do not have any playlists.
                    // The reason for that is that the playlist UI gives an
                    // overall view of how much of the stuff in your curriculum you have completed.
                    // The messages here provide that overall view for people who do not see that
                    // playlists UI.
                    // The vast majority of our users DO have playlists.  The only ones who don't
                    // are demo users and users from institutions with no playlist_pack_ids
                    if (!scope.currentUser?.hasPlaylists) {
                        if (scope.noStreamsAvailable) {
                            scope.topNavInfo = {
                                title: translationHelper.get('top_nav_uh_oh'),
                                subTitle: translationHelper.get('top_nav_browse'),
                                classNames: ['prominent'],
                            };
                        } else if (scope.allStreamsCompleted && hasAvailableIncompleteStreams) {
                            scope.topNavInfo = {
                                title: translationHelper.get('top_nav_nice_work'),
                                subTitle: translationHelper.get('top_nav_completed_all'),
                                classNames: ['prominent', 'completed'],
                            };
                        } else if (
                            scope.blueOceanHighSchooler &&
                            scope.allStreamsCompleted &&
                            !scope.hasAvailableIncompleteStreams
                        ) {
                            scope.topNavInfo = {
                                title: translationHelper.get('top_nav_completed'),
                                subTitle: translationHelper.get('top_nav_well_done'),
                                classNames: ['prominent', 'completed'],
                            };
                        } else if (
                            !scope.blueOceanHighSchooler &&
                            scope.allStreamsCompleted &&
                            !hasAvailableIncompleteStreams
                        ) {
                            scope.topNavInfo = {
                                title: translationHelper.get('top_nav_all_complete'),
                                subTitle: '',
                                classNames: ['prominent', 'completed'],
                            };
                        }
                    }

                    // There used to be more complex logic here to decide how to order.  It's possible that
                    // there is still more simplification that can happen here.  (This is used inside of
                    // `student_dashboard_courses_flat.html`)
                    scope.flatStreamOrdering = 'title';
                }

                function updateActivePlaylistForLocalePackId() {
                    scope.activePlaylist = deriveActivePlaylist(scope.playlists, scope.currentUser);
                }

                scope.activatePlaylist = playlist => {
                    // skip this junk if it's already the active playlist
                    if (playlist && scope.activePlaylist && playlist.id === scope.activePlaylist.id) {
                        scope.learningBoxMode.mode = 'active_playlist';
                        return;
                    }

                    // save to a local var so that we know not to default to the playlists view after load completes
                    scope.activePlaylist = playlist;
                    scope.currentUser.active_playlist_locale_pack_id = playlist.localePackId;

                    // sometimes we log in as users and change their active playlist
                    // for them, thus the lack of checking for ghostMode
                    scope.currentUser.save();

                    // toggle back to active_playlist mode
                    scope.learningBoxMode.mode = 'active_playlist';
                };

                //---------------------------
                // Data Loading
                //---------------------------

                function loadStudentDashboard() {
                    scope.learningBoxMode = {};

                    scope.streamGroups = undefined;
                    scope.activePlaylist = undefined;
                    scope.playlists = undefined;
                    scope.relevantCohort = undefined;
                    hasAvailableIncompleteStreams = undefined;

                    // load student dashboard data
                    scope.dashboardLoading = true;

                    // rely on learner cache if available
                    LearnerContentCache.ensureStudentDashboard()
                        .then(response => {
                            // Update streams and counts of various statistics
                            const studentDashboard = response.result[0];

                            scope.streamsFromDashboardCall = studentDashboard.lesson_streams;
                            scope.playlists = studentDashboard.available_playlists;

                            updateActivePlaylistForLocalePackId();

                            scope.learningBoxMode.hasSinglePlaylist = scope.playlists && scope.playlists.length === 1;

                            // hasAvailableIncompleteStreams is only defined and relevant for users who have no playlists
                            hasAvailableIncompleteStreams =
                                response.meta && response.meta.has_available_incomplete_streams;

                            scope.relevantCohort = scope.currentUser.relevantCohort;

                            const cohortSupportEnabled = !!scope.currentUser.relevantCohort;

                            // some things only appear if we have a relevant cohort (e.g.: not an external-institution user)
                            if (cohortSupportEnabled) {
                                // determine the first playlist entry in the relevant cohort
                                scope.foundationPlaylist = _.find(scope.playlists, {
                                    localePackId: scope.relevantCohort.foundationsPlaylistLocalePackId,
                                });

                                scope.hasConcentrations = true;

                                scope.numConcentrationPlaylists = scope.relevantCohort.getConcentrationPlaylists(
                                    scope.playlists,
                                ).length;
                            }

                            // decide which mode to show in the learning box
                            if (scope.learningBoxMode.hasSinglePlaylist) {
                                scope.learningBoxMode.mode = 'active_playlist';
                            } else if (scope.activePlaylist && !scope.activePlaylist.complete) {
                                // if there's an active, incomplete playlist, show it
                                scope.learningBoxMode.mode = 'active_playlist';
                            } else if (scope.currentUser.hasPlaylists) {
                                // if there are available playlists, show the playlists view
                                scope.learningBoxMode.mode = 'playlists';
                            } else {
                                scope.learningBoxMode.mode = 'course';
                            }

                            // build list of most recent streams with progress
                            scope.recentStreams = _.chain(scope.streamsFromDashboardCall)
                                .filter(stream => stream.started)
                                .sortBy(stream => -stream.lesson_streams_progress.last_progress_at)
                                .take(3)
                                .value();

                            // HasToggleableDisplayMode controls DOM pre-rendering
                            scope.preloadAllDisplayModes();

                            // load any streams not already loaded by the student dashboard call
                            scope.readyToShowPlaylistMap = false;
                            Playlist.loadStreams(scope.playlists).then(() => {
                                // We have to set the recommended playlist inside of this callback to better prevent "No cached stream found
                                // for locale pack" errors (see https://trello.com/c/qi7m0IBX/1547-bug-no-cached-stream-for-locale-pack).
                                if (scope.activePlaylist && scope.activePlaylist.complete) {
                                    const specialization_lpids = scope.relevantCohort
                                        ? scope.relevantCohort.specialization_playlist_pack_ids
                                        : [];

                                    // Don't recommend complete nor specialization playlists (the user chooses those)
                                    let recommendablePlaylists = _.chain(scope.playlists)
                                        .reject('complete')
                                        .reject(playlist => specialization_lpids.includes(playlist.localePackId))
                                        .filter(playlist => ContentAccessHelper.canLaunch(playlist))
                                        .value();

                                    if (cohortSupportEnabled) {
                                        // Do not recommend the Business Foundations playlist to
                                        // accepted users in a cohort that should exclude the
                                        // foundations playlist on acceptance.
                                        if (
                                            getIsCurrentOrHasCompletedActiveProgram(scope.currentUser) &&
                                            Cohort.excludesFoundationsPlaylistOnAcceptance(
                                                scope.currentUser.programType,
                                            )
                                        ) {
                                            const foundationsPlaylist = _.find(
                                                recommendablePlaylists,
                                                playlist =>
                                                    playlist.localePackId ===
                                                    scope.relevantCohort.foundationsPlaylistLocalePackId,
                                            );
                                            recommendablePlaylists = _.without(
                                                recommendablePlaylists,
                                                foundationsPlaylist,
                                            );
                                        }

                                        // use the playlist order defined by the cohort
                                        scope.recommendedPlaylist = _.chain(recommendablePlaylists)
                                            .sortBy(plist => {
                                                const index = scope.relevantCohort.concentrationPlaylistPackIds.indexOf(
                                                    plist.localePackId,
                                                );
                                                return index > -1 ? index : 9999;
                                            })
                                            .first()
                                            .value();
                                    } else {
                                        scope.recommendedPlaylist = _.chain(recommendablePlaylists)
                                            .sortBy('percentComplete')
                                            .last()
                                            .value();
                                    }
                                }

                                scope.readyToShowPlaylistMap = true;
                            });
                        })
                        .catch(e => {
                            // ensureStudentDashboard can throw a DisconnectedError if
                            // we enter offline mode while it is in flight.  If that happens
                            // we can just ignore the error.
                            if (e.constructor !== DisconnectedError) {
                                throw e;
                            }
                        })
                        .finally(() => {
                            scope.dashboardLoading = false;
                        });
                }

                async function ensureBottomPaddingForMobileSidebarBoxes() {
                    const collapsibleBoxFlags = ['showMobileProgramBox'];

                    const isMobilePortrait = $window.innerWidth <= 767;
                    const isMobileLandscape = $window.innerWidth >= 768 && $window.innerWidth <= 991;

                    let paddingBottom = 0;

                    // increment padding for each standard collapsible box
                    collapsibleBoxFlags.forEach(flag => {
                        if (scope[flag] && isMobilePortrait) paddingBottom += 45;
                        if (scope[flag] && isMobileLandscape) paddingBottom += 65;
                    });

                    // increment padding for each new sidebar box
                    const visibleSidebarBoxes =
                        (await scope.studentDashboardConfig?.mobileSidebarBoxes())?.filter(sidebarBoxConfig => {
                            if (sidebarBoxConfig.primary) {
                                return sidebarBoxConfig.primary.shouldHide !== true;
                            }

                            return sidebarBoxConfig.shouldHide !== true;
                        }) ?? [];
                    visibleSidebarBoxes.forEach(() => {
                        if (isMobilePortrait) paddingBottom += 45;
                        if (isMobileLandscape) paddingBottom += 65;
                    });

                    // add padding for the mobile menu while in portrait
                    if (isMobilePortrait) paddingBottom += $('.app-menu-mobile').outerHeight();

                    $('.student-dashboard').css('padding-bottom', paddingBottom);
                }

                scope.$watchGroup(['showMobileProgramBox', 'studentDashboardConfig'], () => {
                    ensureBottomPaddingForMobileSidebarBoxes();
                });

                $($window).on('resize', () => {
                    ensureBottomPaddingForMobileSidebarBoxes();
                });

                // Watch for the user's active playlist to change in push messages, which
                // can happen if they are accepted into a cohort. See https://trello.com/c/CH2RajXg
                scope.$watch('currentUser.active_playlist_locale_pack_id', newVal => {
                    if (newVal && scope.playlists) {
                        updateActivePlaylistForLocalePackId();
                    }
                });

                scope.$watchGroup(['streamsFromDashboardCall', 'activePlaylist'], () => {
                    createStreamGroups(scope.streamsFromDashboardCall);

                    // helper method added to scope in HasToggleableDisplayMode
                    scope.createTopicGroups(scope.bookmarkedCourses);
                });

                scope.$watch(
                    () => offlineModeManager.inOfflineMode,
                    () => {
                        getStreamsAvailableOffline();
                    },
                );

                // watching inOfflineMode isn't sufficient to ensure we have the most accurate
                // list of streams available for offline use. if a learner goes offline after all
                // network requests to download a particular stream to indexedDB are complete, but
                // before the stream is marked as stored, the sidebar box link for that stream
                // will be disabled unless we listen for this event.
                const allContentStoredCallback = () => {
                    getStreamsAvailableOffline();
                };
                frontRoyalStore.on(ALL_CONTENT_STORED, allContentStoredCallback);
                scope.$on('$destroy', () => {
                    frontRoyalStore.off(ALL_CONTENT_STORED, allContentStoredCallback);
                });

                const offlineStreams = {};

                function getStreamsAvailableOffline() {
                    if (offlineModeManager.inOfflineMode) {
                        // Using getOfflineStreams here to cache the list of streams
                        // available offline. This allows for more performant checking
                        // of offline availability via streamAvailable below over using
                        // OfflineModeManager#streamIsAvailableOffline
                        getOfflineStreams($injector).then(streams => {
                            streams.forEach(stream => {
                                offlineStreams[stream.id] = stream;
                            });
                            safeApply(scope);
                        });
                    }
                }

                scope.streamAvailable = stream => {
                    // Streams can always be accessed when online
                    if (!offlineModeManager.inOfflineMode) {
                        return true;
                    }
                    return !!offlineStreams[stream.id];
                };

                scope.openStreamDashboard = (stream, eventName) => {
                    if (scope.streamAvailable(stream)) {
                        EventLogger.log(eventName);
                        RouteAnimationHelper.animatePathChange(stream.streamDashboardPath, 'slide-left');
                    }
                };

                scope.$on('$destroy', () => {
                    DialogModal.hideAlerts();
                });

                // todoItems is used here inside of onlyNeedsToCompleteCareerProfileForEnrollment.
                const result = getEnrollmentSidebarTodoGroups($injector, {
                    isLoadingBioSig: scope.isLoadingBioSig,
                    enrollBioSig: scope.enrollBioSig,
                });
                [scope.todoItems, scope.enrollmentTodoGroups] = result;

                // Empirically, we have seen that once streamGroups is set, the directive appears to be done loading,
                // so that's when we stop the timer that we started when a user clicked to navigate here.
                scope.$watch('streamGroups', streamGroups => {
                    if (streamGroups) {
                        $timeout().then(() => {
                            timerSingleton.finishTimer('navigateToDashboard', 'rendered_student_dashboard');
                            scope.$emit('renderedStudentDashboard');
                        });
                    }
                });

                loadStudentDashboard();
            },
        };
    },
]);
