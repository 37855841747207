/*
    In general, the rules implemented in this file look at all of the user's user program states to decide what the user
    should have access to. The idea is that you should not lose or gain access to content because you switch pref programs.

    If one of your programs specifically grants you access to a course, then you will have access to that course regardless
    of which is currently selected.

    If one of your programs specficially removes access to a course (because you're missing a user id verification, for example), then that
    course will be locked regardless of which is currently selected.

    One exception is with exam streams. To launch an exam stream, the pref program must be the one that includes that exam. See
    comment in _hasCompletedPrerequisitesWhenPlaylist to understand why.

    It's unclear that these rules would work well in a different world where content was shared across programs in different
    families. In that case, for example, a missing user id verification in one program could lock some of the content in the
    dashboard for another. This isn't a practical problem because we don't share content like that and maybe also because we DO
    kind of share user id verification stuff in practice (but that may not apply to everything that effects access.) If the
    world ever changes and we start sharing content across programs in a way that could break assumption #1, we should get
    an alert from https://metabase.pedago-tools.com/question/2309-playlists-duplicated-across-families

*/
import moize from 'moize';
import { targetBrandConfig } from 'AppBranding';
import {
    getHasPastDueTilaDisclosure,
    getCohort,
    getRelevantUserProgramStates,
    getProgramInclusion,
    getPastDueForIdVerification,
} from 'Users';
import {
    getRequiredStreamPackIdsFromPeriods,
    getPeriodIndexForRequiredLocalePackId,
    getStreamIsInCurriculum,
} from 'Cohorts';
import { mustVerifyIdentityToLaunchStream } from 'ExamVerification';
import { onAdministrativeHold, onSecondAcademicProbationHold, streamIsManuallyUnlocked } from 'ProgramInclusion';
import { cohortUnlocksPlaylist, cohortUnlocksStream } from 'ContentAccess';
import angularModule from '../lessons_module';

function filterUserProgramStates(user, fn) {
    return getRelevantUserProgramStates(user).filter(ups => {
        const programInclusion = getProgramInclusion(user, { userProgramState: ups });
        return fn(ups.cohort, programInclusion);
    });
}

function getHasMatchingProgramInclusion(user, fn) {
    return !!getRelevantUserProgramStates(user).find(ups => {
        const programInclusion = getProgramInclusion(user, { userProgramState: ups });
        return programInclusion && fn(ups.cohort, programInclusion);
    });
}

const userProgramStatesThatUnlockPlaylist = moize((user, playlist) =>
    filterUserProgramStates(user, (cohort, programInclusion) =>
        cohortUnlocksPlaylist(cohort, programInclusion, playlist.localePackId),
    ),
);

const userProgramStatesThatUnlockStream = moize((user, stream) =>
    filterUserProgramStates(user, (cohort, programInclusion) =>
        cohortUnlocksStream(cohort, programInclusion, stream.localePackId),
    ),
);

angularModule.factory('ContentAccessHelper', [
    '$injector',
    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const Stream = $injector.get('Lesson.Stream');
        const Playlist = $injector.get('Playlist');
        const Lesson = $injector.get('Lesson');
        const DialogModal = $injector.get('DialogModal');
        const $location = $injector.get('$location');
        const SuperModel = $injector.get('SuperModel');
        const TranslationHelper = $injector.get('TranslationHelper');
        const ErrorLogService = $injector.get('ErrorLogService');
        const $filter = $injector.get('$filter');
        const _$sanitize = $injector.get('$sanitize');
        const ConfigFactory = $injector.get('ConfigFactory');
        const offlineModeManager = $injector.get('offlineModeManager');

        const translationHelper = new TranslationHelper('lessons.shared.content_access_helper');

        const ContentAccessHelper = SuperModel.subclass(function () {
            this.extend({
                canLaunch(contentItem) {
                    return new ContentAccessHelper(contentItem).canLaunch;
                },

                allowedOffline(contentItem) {
                    return new ContentAccessHelper(contentItem).allowedOffline();
                },

                // With streams and lessons, we show messaging about why a particular
                // content item is locked (for example, on a stream-link-box).  So those
                // messages are set on an instance below.  For playlists, however, we show a more
                // general message on the student dashboard about THIS USER's content is locked. So
                // that is set here in a class method. Since this is at the user level, we don't check all
                // of the user program states when checking id verification, TILA, etc.
                // See comment at the top of this page about the danger of sharing playlists across
                // program families.
                playlistsLockedReasonKey(user) {
                    let key;
                    if (user.isNotJoiningProgramOrHasBeenExpelled) {
                        key = 'locked_curriculum_message_rejected';
                    } else if (onSecondAcademicProbationHold(getProgramInclusion(user))) {
                        key = 'reason_message_playlist_placed_on_academic_second_probation_hold';
                    } else if (onAdministrativeHold(getProgramInclusion(user))) {
                        key = 'reason_message_playlist_placed_on_administrative_hold';
                    } else if (user.pastDueForIdVerification) {
                        key = 'reason_message_playlist_requires_idology_verification';
                    } else if (getHasPastDueTilaDisclosure(user)) {
                        key = 'reason_message_playlist_requires_signed_tila_disclosure';
                    } else if (getProgramInclusion(user)?.lockedDueToBillingIssue) {
                        key = 'locked_curriculum_message_payment_overdue';
                    } else {
                        key = 'locked_curriculum_message';
                    }
                    return key; // translated elsewhere with translate-compile because it may contain HTML
                },
            });

            Object.defineProperty(this.prototype, 'canLaunch', {
                get() {
                    const contentItem = this.contentItem;

                    if (this._canLaunch === undefined) {
                        if (!contentItem) {
                            this._canLaunch = false;
                        } else if (contentItem.isA(Playlist)) {
                            this._canLaunch = this._canLaunchPlaylist(contentItem);
                        } else if (contentItem.isA(Stream)) {
                            this._canLaunch = this._canLaunchStream(contentItem);
                        } else if (contentItem.isA(Lesson)) {
                            this._canLaunch = this._canLaunchLesson(contentItem);
                        } else {
                            throw new Error(
                                'Trying to launch a content item that is not a Lesson, Stream, or Playlist',
                            );
                        }
                    }

                    return this._canLaunch;
                },
                // specs
                configurable: true,
            });

            // Used on buttons in places like the keep learning section
            Object.defineProperty(this.prototype, 'launchText', {
                get() {
                    // we special case completed streams
                    const completedExam =
                        this.contentItem.isA(Stream) && this.contentItem.exam && this.contentItem.complete;
                    if (this.canLaunch && !completedExam) {
                        return this.contentItem.launchText;
                    }
                    if (this.contentItem.complete) {
                        return translationHelper.get('completed');
                    }
                    return this.translationHelper.get('locked');
                },
                configurable: true,
            });

            // Used in modals and tooltips to describe why the item is locked
            Object.defineProperty(this.prototype, 'reasonMessage', {
                get() {
                    if (this.canLaunch) {
                        return null;
                    }

                    // if this is a lesson, and it cannot be launched because
                    // of an issue with the stream, the show the message related
                    // to the stream
                    if (this._delegateReasonTo) {
                        return this._delegateReasonTo.reasonMessage;
                    }

                    if (!this._reasonMessage) {
                        this._reasonMessage = this._getReasonText('message');
                    }

                    return this._reasonMessage;
                },
                configurable: true,
            });

            // property that can be mocked in specs
            Object.defineProperty(this.prototype, 'reason', {
                get() {
                    // need to make sure 'canLaunch' has been triggered so that
                    // _reason will be set
                    if (this.canLaunch) {
                        return null;
                    }

                    // if this is a lesson, and it cannot be launched because
                    // of an issue with the stream, the show the reason related
                    // to the stream
                    if (this._delegateReasonTo) {
                        return this._delegateReasonTo.reason;
                    }
                    return this._reason;
                },
                configurable: true,
            });

            // used as the title of modals
            Object.defineProperty(this.prototype, 'reasonTitle', {
                get() {
                    if (this.canLaunch) {
                        return null;
                    }

                    // if this is a lesson, and it cannot be launched because
                    // of an issue with the stream, then show the message related
                    // to the stream
                    if (this._delegateReasonTo) {
                        return this._delegateReasonTo.reasonTitle;
                    }

                    if (!this._reasonTitle) {
                        this._reasonTitle = this._getReasonText('title');
                    }

                    return this._reasonTitle;
                },
                configurable: true,
            });

            return {
                REASON_NO_USER: 'no_user',
                REASON_COMING_SOON: 'coming_soon',
                REASON_LOCKED: 'locked',
                REASON_UNAVAILABLE_FOR_OFFLINE_USE: 'unavailable_offline',
                REASON_UNMET_PREREQUISITES_SCHEDULE: 'unmet_prerequisites_schedule',
                REASON_UNMET_PREREQUISITES_PLAYLIST: 'unmet_prerequisites_playlist',
                REASON_UNMET_PREREQUISITES_CONCENTRATION: 'unmet_prerequisites_concentration',
                REASON_UNMET_PREREQUISITES_SPECIALIZATION: 'unmet_prerequisites_specialization',
                REASON_BEFORE_LAUNCH_WINDOW: 'before_launch_window',
                REASON_EXAM_TIME_RUN_OUT: 'exam_time_run_out',
                REASON_COMPLETED_TEST: 'completed_test',
                REASON_BEFORE_LAUNCH_WINDOW_AND_UNMET_PREREQUISITES_SCHEDULE:
                    'before_launch_window_and_unmet_prerequisites_schedule',
                REASON_BEFORE_LAUNCH_WINDOW_AND_UNMET_PREREQUISITES_CONCENTRATION:
                    'before_launch_window_and_unmet_prerequisites_concentration',
                REASON_BEFORE_LAUNCH_WINDOW_AND_UNMET_PREREQUISITES_SPECIALIZATION:
                    'before_launch_window_and_unmet_prerequisites_specialization',
                REASON_BEFORE_LAUNCH_WINDOW_AND_UNMET_PREREQUISITES_PLAYLIST:
                    'before_launch_window_and_unmet_prerequisites_playlist',
                REASON_REQUIRES_SIGNED_TILA_DISCLOSURE: 'requires_signed_tila_disclosure',
                REASON_ACADEMIC_HOLD: 'placed_on_academic_hold',
                REASON_REQUIRES_IDOLOGY_VERIFICATION: 'requires_idology_verification',
                REASON_REQUIRES_BIOSIG_VERIFICATION: 'requires_biosig_verification',
                REASON_MUST_SWITCH_PROGRAM_TO_LAUNCH_EXAM: 'must_switch_program_to_launch_exam',

                initialize(contentItem, user, contentStoredForOfflineUse) {
                    this.user = user || $rootScope.currentUser;
                    this.contentItem = contentItem;
                    this.contentStoredForOfflineUse = contentStoredForOfflineUse;

                    // put it here so it is accessible for mocking in specs
                    this.translationHelper = translationHelper;

                    if (!contentItem) {
                        this.contentType = null;
                    } else if (contentItem.isA(Playlist)) {
                        this.contentType = 'playlist';
                    } else if (contentItem.isA(Stream)) {
                        this.contentType = 'stream';
                    } else if (contentItem.isA(Lesson)) {
                        this.contentType = 'lesson';
                    }
                },

                showModalIfCannotLaunch() {
                    if (!this.canLaunch) {
                        DialogModal.alert({
                            content: `<p class="message">${this.reasonMessage}</p><button class="modal-action-button" ng-click="close()">${this.reasonTitle}</button>`,
                            size: 'small',
                            hideCloseButton: true,
                            closeOnClick: true,
                            close() {
                                $location.url('/dashboard');
                            },
                            blurTargetSelector: 'div[ng-controller]',
                        });
                    }
                },

                // This one seems a bit odd, but in order to leave the stream dashboard
                // behavior as-is, it was necessary.  The stream dashboard is normally
                // accessible for streams that cannot be launched (though there is no
                // way to navigate to it, so we don't really expect people to see it).
                // In just the unmet prereq case though, we actually force people away
                // from it.  It's maybe odd and maybe accidental that we do that in the
                // prereq case but not the before examOpen case, but not a big deal either way.
                showModalIfPrereqsNotMet() {
                    if (!this._hasCompletedPrerequisites()) {
                        this.showModalIfCannotLaunch();
                    }
                },

                allowedOffline() {
                    if (this.contentType === 'stream') {
                        return !this.contentItem.exam;
                    }

                    if (this.contentType === 'lesson') {
                        return !this.contentItem.testOrAssessment;
                    }

                    return true;
                },

                _canLaunchPlaylist() {
                    if (!this.user) {
                        this._reason = this.REASON_NO_USER;
                        return false;
                    }
                    if (this.user.inGroup('SUPERVIEWER')) {
                        return true;
                    }
                    if (this._isPlaylistLocked(this.contentItem)) {
                        this._reason = this.REASON_LOCKED;
                        return false;
                    }
                    if (
                        getProgramInclusion(this.user)?.academicHold ||
                        onSecondAcademicProbationHold(getProgramInclusion(this.user))
                    ) {
                        this._reason = this.REASON_ACADEMIC_HOLD;
                        return false;
                    }
                    if (
                        this._missingTilaOrIdVerification(
                            userProgramStatesThatUnlockPlaylist((this.user, this.contentItem)),
                        )
                    ) {
                        return false;
                    }
                    return true;
                },

                _missingTilaOrIdVerification(userProgramStates) {
                    const unverifiedUps = userProgramStates.find(ups =>
                        getPastDueForIdVerification(this.user, { userProgramState: ups }),
                    );
                    if (unverifiedUps) {
                        this._reason = this.REASON_REQUIRES_IDOLOGY_VERIFICATION;
                        return true;
                    }
                    const upsWithoutTila = userProgramStates.find(ups =>
                        getHasPastDueTilaDisclosure(this.user, { userProgramState: ups }),
                    );
                    if (upsWithoutTila) {
                        this._reason = this.REASON_REQUIRES_SIGNED_TILA_DISCLOSURE;
                        return true;
                    }

                    return false;
                },

                _canLaunchStream() {
                    const stream = this.contentItem;
                    if (!this.user) {
                        this._reason = this.REASON_NO_USER;
                        return false;
                    }

                    // In practice, there would never be a case where allowedOffline() would be false and
                    // contentStoredForOfflineUse would be true, because we don't load content for streams that
                    // are not allowed offline. So, you could remove the check on allowedOffline without changing
                    // anything. But, conceptually, it makes sense to have it here.
                    if (
                        offlineModeManager.inOfflineMode &&
                        (this.contentStoredForOfflineUse === false || !this.allowedOffline())
                    ) {
                        this._reason = this.REASON_UNAVAILABLE_FOR_OFFLINE_USE;
                        return false;
                    }
                    if (this.user.inGroup('SUPERVIEWER')) {
                        return true;
                    }
                    if (this._isStreamLocked(stream)) {
                        this._reason = this.REASON_LOCKED;
                        return false;
                    }
                    // NOTE: if the time limit is run out or we are after the launch window,
                    // the stream dashboard can be opened, but the lessons within it cannot be
                    // (see canLaunchLesson below).
                    if (!stream.complete) {
                        // We cannot check prerequisites for cohorts other than the relevant cohort, so
                        // exams from other cohorts will always be locked. See comment in _hasCompletedPrerequisitesWhenPlaylist
                        // to understand why this is
                        if (this._mustSwitchProgramsToLaunchExam()) {
                            this._reason = this.REASON_MUST_SWITCH_PROGRAM_TO_LAUNCH_EXAM;
                            return false;
                        }

                        // Everything inside of this block only checks the relevant cohort for the user.
                        // See comment in _hasCompletedPrerequisitesWhenPlaylist for an explanation of why
                        // we do that here, when in other cases we check all the user's user program states.
                        if (stream.beforeLaunchWindow(this.user)) {
                            this._reason = this.REASON_BEFORE_LAUNCH_WINDOW;
                            if (!this._hasCompletedPrerequisitesWhenSchedule()) {
                                this._reason = this.REASON_BEFORE_LAUNCH_WINDOW_AND_UNMET_PREREQUISITES_SCHEDULE;
                            } else if (!this._hasCompletedPrerequisitesWhenPlaylist('concentration')) {
                                this._reason = this.REASON_BEFORE_LAUNCH_WINDOW_AND_UNMET_PREREQUISITES_CONCENTRATION;
                            } else if (!this._hasCompletedPrerequisitesWhenPlaylist('specialization')) {
                                this._reason = this.REASON_BEFORE_LAUNCH_WINDOW_AND_UNMET_PREREQUISITES_SPECIALIZATION;
                            }
                            return false;
                        }

                        if (!this._hasCompletedPrerequisitesWhenSchedule()) {
                            this._reason = this.REASON_UNMET_PREREQUISITES_SCHEDULE;
                            return false;
                        }
                        if (!this._hasCompletedPrerequisitesWhenPlaylist('concentration')) {
                            this._reason = this.REASON_UNMET_PREREQUISITES_CONCENTRATION;
                            return false;
                        }
                        if (!this._hasCompletedPrerequisitesWhenPlaylist('specialization')) {
                            this._reason = this.REASON_UNMET_PREREQUISITES_SPECIALIZATION;
                            return false;
                        }
                    }
                    if (stream.coming_soon) {
                        this._reason = this.REASON_COMING_SOON;
                        return false;
                    }
                    if (
                        getProgramInclusion(this.user)?.academicHold ||
                        onSecondAcademicProbationHold(getProgramInclusion(this.user))
                    ) {
                        this._reason = this.REASON_ACADEMIC_HOLD;
                        return false;
                    }
                    if (
                        this._missingTilaOrIdVerification(
                            userProgramStatesThatUnlockStream(this.user, this.contentItem),
                        )
                    ) {
                        return false;
                    }
                    return true;
                },

                _mustSwitchProgramsToLaunchExam() {
                    return (
                        this._shouldCheckPrerequisitesForExam() &&
                        !getStreamIsInCurriculum(getCohort(this.user), this.contentItem.localePackId)
                    );
                },

                _canLaunchLesson() {
                    const lesson = this.contentItem;

                    if (lesson.stream()) {
                        const streamContentAccessHelper = new ContentAccessHelper(lesson.stream(), this.user);
                        if (!streamContentAccessHelper.canLaunch) {
                            this._delegateReasonTo = streamContentAccessHelper;
                            this._canLaunch = false;
                            return false;
                        }
                    }

                    if (!this.allowedOffline() && offlineModeManager.inOfflineMode) {
                        this._reason = this.REASON_UNAVAILABLE_FOR_OFFLINE_USE;
                        return false;
                    }

                    if (
                        this.user &&
                        this.user.inGroup('SUPERVIEWER') &&
                        // This check will ensure that superviewers must go through the proctoring
                        // process to launch an exam (if it's required by their relevantCohort).
                        // This is valuable because we'd like internal users to experience the UX.
                        !mustVerifyIdentityToLaunchStream(this.user, lesson.stream(), ConfigFactory.getSync())
                    ) {
                        return true;
                    }

                    if (lesson.comingSoon) {
                        this._reason = this.REASON_COMING_SOON;
                        return false;
                    }
                    if (lesson.unrestricted) {
                        return true;
                    }
                    if (!this.user) {
                        this._reason = this.REASON_NO_USER;
                        return false;
                    }
                    if (lesson.completedTest) {
                        this._reason = this.REASON_COMPLETED_TEST;
                        return false;
                    }
                    if (lesson.stream() && lesson.stream().msLeftInTimeLimit === 0) {
                        this._reason = this.REASON_EXAM_TIME_RUN_OUT;
                        return false;
                    }
                    // This is checking for an ExamVerification record associated with this
                    // Lesson's Stream. We don't do this in canLaunchStream because we want
                    // users to be able to load the Stream Dashboard in order to verify their
                    // identity, we just don't want them to be able to launch the exam Stream's
                    // lesson(s).
                    // Important note: this check has an intentional side effect of excluding lessons
                    // from proctored exam Streams from our keepLearningLesson logic. This means
                    // that a user can't bypass the Stream dashboard and launch directly into a
                    // proctored exam.
                    if (mustVerifyIdentityToLaunchStream(this.user, lesson.stream(), ConfigFactory.getSync())) {
                        this._reason = this.REASON_REQUIRES_BIOSIG_VERIFICATION;
                        return false;
                    }

                    return true;
                },

                _globalLockingState() {
                    if (!this.user) return true;
                    if (!this.user.mba_content_lockable) return false;
                    return null;
                },

                _isPlaylistLocked() {
                    const playlist = this.contentItem;

                    if (this._globalLockingState() !== null) {
                        return this._globalLockingState();
                    }

                    return userProgramStatesThatUnlockPlaylist(this.user, playlist).length === 0;
                },

                _isStreamLocked() {
                    const stream = this.contentItem;

                    if (this._globalLockingState() !== null) {
                        return this._globalLockingState();
                    }

                    // unlock any stream that is in a group associated with the user
                    if (this.user.streamIsAssociatedWithAccessGroup(stream.localePackId)) {
                        return false;
                    }

                    return !userProgramStatesThatUnlockStream(this.user, stream)[0];
                },

                _hasCompletedPrerequisites() {
                    return (
                        this._hasCompletedPrerequisitesWhenSchedule() && this._hasCompletedPrerequisitesWhenPlaylist()
                    );
                },

                _hasCompletedPrerequisitesWhenPlaylist(type) {
                    if (!this._shouldCheckPrerequisitesForExam()) {
                        return true;
                    }

                    const stream = this.contentItem;

                    // In all the rest of the places in ContentAccessHelper, we check all the different programs to
                    // see if the user should have access. The only reason we're not doing that here is becuase of
                    // the use of Playlist.getCachedForLocalePackId. For all the cohorts other than the relevant one,
                    // we can't expect the playlists to be loaded, so we can't check them.
                    // See call to _mustSwitchProgramsToLaunchExam to see how we avoid getting here when the user is switched
                    // to a different cohort
                    const cohort = this.user.relevantCohort;
                    let cohortPackIds = cohort.playlistPackIds;
                    if (type === 'specialization') {
                        cohortPackIds = cohort.specialization_playlist_pack_ids;
                    } else if (type === 'concentration') {
                        cohortPackIds = cohort.concentrationPlaylistPackIds;
                    }
                    let requiredStreamLocalePackIds = [];

                    // Check if the exam is in a playlist
                    _.forEach(cohortPackIds, playlistPackId => {
                        const playlist = Playlist.getCachedForLocalePackId(playlistPackId, false);

                        // It's possible that one of the locale pack ids in specialization_playlist_pack_ids or
                        // concentrationPlaylistPackIds corresponds to an unpublished playlist.  If that's the
                        // case, then the client should behave as though that playlist does not exist at all
                        // and is not attached to the cohort, so we can skip right over it.
                        // see https://trello.com/c/7OkCSP4I.
                        if (!playlist) {
                            return;
                        }

                        const streamEntryLocalePackIds = _.map(playlist.stream_entries, 'locale_pack_id');

                        // Find the position of this stream in the playlist (if it's there at all)
                        const examEntryIndex = streamEntryLocalePackIds.indexOf(stream.localePackId);

                        // Go from the first stream entry up to (not inclusive of) the entry that is the exam.
                        if (examEntryIndex > 0) {
                            const packIdsBeforeThisOneInPlaylist = streamEntryLocalePackIds.slice(0, examEntryIndex);
                            requiredStreamLocalePackIds =
                                requiredStreamLocalePackIds.concat(packIdsBeforeThisOneInPlaylist);
                        }
                    });
                    return this._hasCompletedAllStreamsAndStartedExams(requiredStreamLocalePackIds);
                },

                _hasCompletedPrerequisitesWhenSchedule() {
                    if (!this._shouldCheckPrerequisitesForExam()) {
                        return true;
                    }

                    const stream = this.contentItem;

                    // It would be possible in this method to check all the cohorts. We don't just to be consistent
                    // with _hasCompletedPrerequisitesWhenPlaylist, where that is not possible. See comment there.
                    const cohort = getCohort(this.user);

                    // Check if the exam is in a period
                    const examPeriodIndex = getPeriodIndexForRequiredLocalePackId(cohort, stream.localePackId);

                    // Go from the first period up to (not inclusive of) the period that contains the exam.
                    // (The method here handles the case when the exam is not found in a period)
                    const requiredStreamLocalePackIds = getRequiredStreamPackIdsFromPeriods(
                        cohort,
                        examPeriodIndex - 1,
                    );
                    return this._hasCompletedAllStreamsAndStartedExams(requiredStreamLocalePackIds);
                },

                // Determines if the user has completed all required steams contained in the streamLocalePackIds param.
                // If the stream is an exam, it only checks if the exam has been started. This avoids creating situations
                // where the user has to take an exam, but can't because they haven't finished an exam required previously
                // in the schedule (see https://trello.com/c/J0GP65Qu).
                _hasCompletedAllStreamsAndStartedExams(streamLocalePackIds = []) {
                    // Search for a stream that's incomplete or is an exam that hasn't been started yet
                    const incompleteStreamOrUnstartedExam = _.find(streamLocalePackIds, streamLocalePackId => {
                        const stream = Stream.getCachedForLocalePackId(streamLocalePackId);
                        // we only check if the stream has been started if the stream is an exam;
                        // otherwise we check if the stream has been completed
                        return stream.exam ? stream.notStarted : !stream.complete;
                    });
                    return !incompleteStreamOrUnstartedExam;
                },

                _shouldCheckPrerequisitesForExam() {
                    const stream = this.contentItem;

                    // only users in a cohort have prereqs
                    if (!this.user || !this.user.relevantCohort) {
                        return false;
                    }

                    // only exams have prereqs
                    if (!stream.exam) {
                        return false;
                    }

                    // An admin may have manually marked the exam stream as unlocked on user's activeProgramInclusion.
                    const hasProgramThatManuallyUnlocksStream = getHasMatchingProgramInclusion(
                        this.user,
                        (_cohort, programInclusion) => streamIsManuallyUnlocked(programInclusion, stream.localePackId),
                    );
                    if (hasProgramThatManuallyUnlocksStream) return false;

                    return true;
                },

                _notifyOnMissingLaunchReason() {
                    if (this._alreadyNotifiedOnMissingLaunchReason) {
                        return;
                    }
                    this._alreadyNotifiedOnMissingLaunchReason = true;

                    ErrorLogService.notify('reason not set.', {
                        userId: this.user && this.user.id,
                        contentType: this.contentType,
                        contentItemId: this.contentItem.id,
                    });
                },

                _getReasonText(textType) {
                    // Text will use a key like
                    // reason_message_lesson_locked or reason_title_stream_unmet_prerequisites
                    let key;
                    const defaultKey = ['reason', textType, this.contentType, 'default'].join('_');
                    if (!this._reason) {
                        this._notifyOnMissingLaunchReason();
                        key = defaultKey;
                    } else {
                        key = ['reason', textType, this.contentType, this._reason].join('_');
                    }

                    const examOpenTime = this.contentItem.examOpenTime && this.contentItem.examOpenTime(this.user);

                    // try to translate the key.  If it is not found, translationHelper
                    // will notify the ErrorLogService and fall back to the default locked message
                    return this.translationHelper.getWithFallbackKey(key, defaultKey, {
                        // any params that will be needed for any of these translations
                        // should be included here
                        openTimeStr: $filter('amDateFormat')(examOpenTime, 'MMMM D, YYYY'),
                        brandEmail: targetBrandConfig(this.user, ConfigFactory.getSync()).emailAddressForUsername(
                            'support',
                        ),
                    });
                },
            };
        });
        return ContentAccessHelper;
    },
]);
